<template>

  <section class="login">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <div class="col-xl-4 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-5 same_modal">
                <div class="brand-logo text-center">
                  <!-- <img src="@/assets/images/believeLogoMini.png" style="width: 100px;"> -->
                  <img src="@/assets/images/logo-footer.webp" style="width: 100px;">
                </div>
                <h4 class="text-center">Hello! let's get started</h4>
                <h6 class="font-weight-light text-center">Sign in to continue.</h6>
                <form class="pt-3" @submit.prevent="onSubmit">
                  <div class="form-group" :class="{ error: v$.form.email.$errors.length }">
                    <input type="email" class="form-control form-control-lg" id="exampleInputEmail1"
                      placeholder="Username" v-model="v$.form.email.$model" required>
                    <div class="input-errors" v-for="(error, index) of v$.form.email.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="form-group" :class="{ error: v$.form.password.$errors.length }">
                    <div class="input-group">
                      <input :type="passwordFieldType" class="form-control form-control-lg" id="exampleInputPassword1"
                        placeholder="Password" required v-model="v$.form.password.$model">
                      <div class="input-group-prepend" style="cursor:pointer" @click="showPassword()">
                        <div class="input-group-text"><i :class="eyeIcon"></i></div>
                      </div>
                    </div>
                    <div class="input-errors" v-for="(error, index) of v$.form.password.$errors" :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                  </div>


                  <!-- <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleInput">Password</label>
                      <div class="input-group">
                        <input :type="passwordFieldType" class="form-control" v-model="form.password"
                          id="inlineFormInputGroup" placeholder="Password">
                        <div class="input-group-prepend" style="cursor:pointer" @click="showPassword()">
                          <div class="input-group-text"><i :class="eyeIcon"></i></div>
                        </div>
                      </div>
                    </div>
                    <p class="err" v-if="pass_err != ''">{{ pass_err }}</p>
                  </div> -->


                  <router-link tabindex="-1" to="/forgetpassword" class="link-primary fs-6 fw-bolder">
                    <i class="fa fa-lock"></i> Forgot your password?
                  </router-link>
                  <div class="mt-3">
                    <button type="button" v-on:click="logIn()"
                      class="btn btn-block same_btn btn-lg font-weight-medium auth-form-btn believe-btn"
                      :disabled="v$.form.$invalid">SIGN IN</button>
                  </div>
                  <!-- <div class="my-2 d-flex justify-content-between align-items-center">
                    <div class="form-check">
                      <label class="form-check-label text-muted">
                        <input type="checkbox" class="form-check-input">
                        Keep me signed in
                        <i class="input-helper"></i>
                      </label>
                    </div>
                    <a href="javascript:void(0);" class="auth-link text-black">Forgot password?</a>
                  </div> -->
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
    <vue-snotify></vue-snotify>
  </section>

</template>

<script>
import api from "../../config/api.js";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import {
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";
export default {
  name: 'login',
  data() {
    return {
      passwordFieldType: 'password',
      eyeIcon: 'fa fa-eye',
      email: "",
      password: "",
      ffname: "",
      lname: "",
      form: {
        email: "",
        password: "",
      },
      error: null,
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        password: {
          required,
          // min: minLength(6),
        },
      },
    };
  },

  methods: {

    showPassword() {
      if (this.passwordFieldType == "password") {
        this.passwordFieldType = "text"
        this.eyeIcon = "fa fa-eye-slash"
      } else {
        this.passwordFieldType = "password"
        this.eyeIcon = "fa fa-eye"
      }
    },

    async logIn() {
      try {
        // this.btnDissable = true;
        // this.success_message = true;
        // this.error_message = "";
        const auth = getAuth();
        const firebase_response = await signInWithEmailAndPassword(
          auth,
          this.form.email,
          this.form.password
        );
        console.log('fb response : ' , backend_response);
        const backend_response = await api.post("/firebase", {
          token: firebase_response?.user?.accessToken,
          is_admin : true
        });
        console.log('fb response : ' , backend_response);
        // console.log("firebase", firebase_response);
        // this.btnDissable = false;
        // this.success_message = false;
        localStorage.setItem("token", backend_response.data.token);
        
        if(backend_response.status == 200){
          this.$router.push({ path: '/' });
        }
        // this.$store.state.user = backend_response.data.detail;
        // localStorage.setItem(
        //   "user",
        //   JSON.stringify(backend_response.data.detail)
        // );
        // let has_promo = localStorage.getItem("has_promo");
        // if (has_promo == "true") {
        //   this.hasPromoRoute();
        // } else {
        //   await this.getUserSubs();
        //   this.$store.state.revcatSubType != "Yearly" ? this.$router.push("/plans") : this.$router.push("/manage");
        //   localStorage.setItem('revcatSubType',this.$store.state.revcatSubType);
        // }
      } catch (error) {
        let error_message = error?.response?.data?.message ?? error;
        // this.success_message = false;
        // this.btnDissable = false;
        // this.error_message = error.code.split("/")[1].toUpperCase()
        console.log('this is err  : ' , error_message);
        // let error2 = error.response.data.message;
        // this.$toast.error(this.error);
        this.showSnotifyError(error_message)
      }
    },

    // async logIn() {
    //   try {
    //     let result = await api.post(`/admin/login`, {
    //       email: this.form.email,
    //       password: this.form.password,
    //     });
    //     localStorage.setItem("token", result.data.token);
    //     // localStorage.setItem("reload", 0);
    //     // this.$toast.success("Welcome back." + result.data.message);
    //     this.$router.push({ path: '/' });
    //   } catch (error) {
    //     let error2 = error.response.data.message;
    //     // this.$toast.error(this.error);
    //     this.showSnotifyError(error2)
    //   }
    // },

    showSnotifyError(msg) {
      this.$snotify.error(msg, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },
  },
}
</script>
